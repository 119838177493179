import * as React from "react";
import { HeadFC, Link } from "gatsby";
import { CustomHead } from "../components/shell/CustomHead";
import Shell from "../components/shell/Shell";
import ImageHero from "../components/shared/ImageHero";
import { StaticImage } from "gatsby-plugin-image";
import ImageCard from "../components/shared/cards/ImageCard";

const pageDescription =
  "Easily make your claim application online by following our simple 4-step claims process.";
const pagesBreadcrumb = [
  {
    name: "Claims",
    to: "/claims",
  },
];

const linkClassNames =
  "text-md font-medium leading-4 text-gray-500 underline decoration-indigo-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2";

const ClaimsPage = () => {
  return (
    <Shell>
      <ImageHero
        title="Claims made simple"
        description={pageDescription}
        pages={pagesBreadcrumb}
      />
      <div className="bg-gray-100">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-4">
            <ImageCard
              subtitle="Step 1"
              title="Claims Notification"
              description="Submit your claim form with all the supporting documetation."
            >
              <StaticImage
                className="w-full bg-[#5735e2]"
                src="../images/claims-1.png"
                alt="A paper kite illustration on a blue background"
              />
            </ImageCard>
            <ImageCard
              subtitle="Step 2"
              title="Document Review"
              description="We will alert you that we have received your claim and process submitted documents."
            >
              <StaticImage
                className="w-full bg-[#5735e2]"
                src="../images/claims-2.png"
                alt="A document review with spyglass illustration on a blue background"
              />
            </ImageCard>
            <ImageCard
              subtitle="Step 3"
              title="Capture Claim"
              description="We will capture your claim for payment and update your contract."
            >
              <StaticImage
                className="w-full bg-[#5735e2]"
                src="../images/claims-3.png"
                alt="A clipboard with a pen illustration on a blue background"
              />
            </ImageCard>
            <ImageCard
              subtitle="Step 4"
              title="Payment"
              description="Payment will be made to the beneficiary bank account and proof of payment will be sent to you."
            >
              <StaticImage
                className="w-full bg-[#5735e2]"
                src="../images/claims-4.png"
                alt="A phone with a bank card and payment validation illustration on a blue background"
              />
            </ImageCard>
          </div>
          <div className="mt-16 rounded-md bg-slate-200 p-4 shadow-md md:p-6 md:p-8">
            <h1 className="text-xl font-medium md:text-2xl lg:text-3xl">
              Claim Now!
            </h1>
            <p className="divide mt-4 space-y-6 text-lg text-gray-500">
              <ul className="list-inside list-disc">
                <li>
                  <a
                    href="../../Nora_Finance_Funeral_Claim_Form_v1.pdf"
                    target="_blank"
                    download
                    rel="noopener noreferrer"
                    className={linkClassNames}
                  >
                    Download
                  </a>{" "}
                  the claims application form.
                </li>
                <li>
                  <Link to="/auth/signin" className={linkClassNames}>
                    Sign In
                  </Link>{" "}
                  to The Dashboard or{" "}
                  <Link to="/auth/signup" className={linkClassNames}>
                    Sign Up
                  </Link>{" "}
                  if you don't have an existing account.
                </li>
                <li>
                  <Link to="/dashboard/new/claim" className={linkClassNames}>
                    Submit
                  </Link>{" "}
                  your form on the claims application page.
                </li>
              </ul>
              <p>
                Alternatively, you can email the form to{" "}
                <a
                  href="mailto:claims@norafinance.co.za"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={linkClassNames}
                >
                  claims@norafinance.co.za
                </a>
                .
              </p>
            </p>
          </div>
        </div>
      </div>
    </Shell>
  );
};

export default ClaimsPage;

export const Head: HeadFC = () => (
  <CustomHead title="Claims" description={pageDescription} />
);
