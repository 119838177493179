import * as React from "react";

export interface ImageCardProps {
  title: string;
  subtitle?: string;
  description: string;
  children: React.ReactNode;
}

const ImageCard = ({
  title,
  subtitle,
  description,
  children,
}: ImageCardProps) => {
  return (
    <div className="overflow-hidden rounded-md bg-white shadow-md">
      <div className="right-0scale-100 relative top-0 left-0 bottom-0 transition-transform duration-1000 hover:scale-105">
        {children}
        <div className="absolute top-0 left-0 h-full w-full transition-colors duration-500 hover:bg-black/30"></div>
      </div>
      <div className="p-4 md:p-6 md:p-8">
        {subtitle && <h1 className="mt-4 text-sm text-gray-500">{subtitle}</h1>}
        <h2 className="mt-4 text-2xl font-medium">{title}</h2>
        <p className="mt-2 text-base text-gray-500">{description}</p>
      </div>
    </div>
  );
};

export default ImageCard;
